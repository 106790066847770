var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","no-fade":""}},[_c('div',{staticClass:"m-2"},[_c('validation-observer',{ref:"resourceRules"},[_c('b-form',[_c('b-row',[_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"font-size":"1.2em"},attrs:{"scope":"col"}},[_vm._v("Add Support Document")])])])])]),_c('b-row',{staticClass:"mt-2 p-2"},[_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[(_vm.id != null)?_c('b-button',{staticClass:"mr-1 float-right",attrs:{"variant":"danger"},on:{"click":_vm.deleteResource}},[_vm._v(" Delete ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","xl":"1"}}),_c('b-col',{attrs:{"cols":"12","xl":"5","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","type":"text","state":_vm.titleState},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticStyle:{"max-width":"100%"},attrs:{"id":"symbol","rows":"4","state":_vm.descriptionState},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"1"}}),_c('b-col',{attrs:{"cols":"12","xl":"4","lg":"12","md":"12","sm":"12"}},[_c('b-form-group',[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"mr-3",staticStyle:{"width":"50%"}},[_c('h6',[_vm._v("Preview Image")]),(_vm.image)?_c('profile-cropper',{attrs:{"image":_vm.image},on:{"cropped":_vm.imageCropped}}):_vm._e(),_c('div',{staticClass:"w-100 text-center"},[(_vm.imageUrl == null)?_c('img',{attrs:{"frameborder":"0","height":"200px","width":"200px","src":require("@/assets/images/image.jpg")}}):_vm._e()]),_c('div',{staticClass:"w-100 text-center"},[(_vm.imageUrl != null)?_c('img',{attrs:{"frameborder":"0","height":"200px","width":"200px","src":_vm.imageUrl}}):_vm._e(),(_vm.id != null)?_c('b-button',{staticClass:"mt-1",staticStyle:{"width":"100%"},attrs:{"href":_vm.imageUrl,"download":"","block":"","variant":"warning"}},[_vm._v("Download")]):_vm._e()],1),_c('b-form-group',{attrs:{"state":_vm.imageState}},[_c('validation-provider',{attrs:{"name":"Preview Image","rules":_vm.imageUrl == null ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"animate__animated animate__fadeIn mt-1",attrs:{"accept":"image/*","type":"file","state":_vm.imageState,"placeholder":"Choose an image or drop it here...","drop-placeholder":"Drop image here..."},on:{"input":_vm.imageSelected}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticStyle:{"width":"50%"}},[_c('h6',[_vm._v("Document")]),_c('div',{staticClass:"w-100 text-center"},[(_vm.document == null)?_c('img',{staticClass:"inactivePdf",attrs:{"frameborder":"0","height":"200px","width":"200px","src":require("@/assets/images/pdf.png")}}):_c('img',{attrs:{"frameborder":"0","height":"200px","width":"200px","src":require("@/assets/images/pdf.png")}})]),(_vm.id != null)?_c('b-button',{staticClass:"mt-1",staticStyle:{"width":"100%"},attrs:{"download":"","href":_vm.document,"block":"","variant":"warning"}},[_vm._v("Download")]):_vm._e(),_c('b-form-group',{attrs:{"state":_vm.fileState}},[_c('validation-provider',{attrs:{"name":"File","rules":_vm.document == null ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"animate__animated animate__fadeIn mt-1",attrs:{"accept":"application/pdf","type":"file","state":_vm.fileState,"placeholder":"Choose a document or drop it here...","drop-placeholder":"Drop PDF here..."},on:{"input":_vm.fileSelected}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])])],1),_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.saveResource.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }