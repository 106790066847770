<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <validation-observer ref="resourceRules">
            <b-form>
              <b-row>
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col" style="font-size: 1.2em;">Add Support Document</th>
                    </tr>
                  </thead>
                </table>
              </b-row>

              <b-row class="mt-2 p-2">
                <b-col cols="12" class="mt-5">
                  <b-button v-if="id != null" @click="deleteResource" variant="danger" class="mr-1 float-right">
                    Delete
                  </b-button>
                </b-col>

                <b-col cols="12" xl="1"> </b-col>

                <b-col cols="12" xl="5" lg="12" md="12" sm="12">
                  <b-form-group label="Title">
                    <validation-provider name="Title" #default="{ errors }" rules="required">
                      <b-form-input id="title" type="text" :state="titleState" v-model="title" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="mt-3" label="Description">
                    <validation-provider name="Description" #default="{ errors }" rules="required">
                      <b-form-textarea style="max-width: 100%" id="symbol" v-model="description" rows="4" :state="descriptionState"></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" xl="1"> </b-col>

                <b-col cols="12" xl="4" lg="12" md="12" sm="12">
                  <b-form-group>
                    <div style="display:flex;">
                      <div class="mr-3" style="width:50%">
                        <h6>Preview Image</h6>
                        <profile-cropper v-if="image" :image="image" @cropped="imageCropped" />

                        <div class="w-100 text-center">
                          <img v-if="imageUrl == null" frameborder="0" height="200px" width="200px" src="@/assets/images/image.jpg" />
                        </div>

                        <div class="w-100 text-center">
                          <img v-if="imageUrl != null" frameborder="0" height="200px" width="200px" :src="imageUrl" />
                          <b-button :href="imageUrl" download class="mt-1" style="width:100%;'" v-if="id != null" block variant="warning">Download</b-button>
                        </div>

                        <b-form-group :state="imageState">
                          <validation-provider name="Preview Image" #default="{ errors }" :rules="imageUrl == null ? 'required' : ''">
                            <b-form-file accept="image/*" @input="imageSelected" type="file" :state="imageState" placeholder="Choose an image or drop it here..." drop-placeholder="Drop image here..." class="animate__animated animate__fadeIn mt-1" />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                      <div style="width:50%">
                        <h6>Document</h6>

                        <div class="w-100 text-center">
                          <img v-if="document == null" frameborder="0" class="inactivePdf" height="200px" width="200px" src="@/assets/images/pdf.png" />
                          <img v-else frameborder="0" height="200px" width="200px" src="@/assets/images/pdf.png" />
                        </div>
                        <b-button class="mt-1" style="width:100%;'" download :href="document" v-if="id != null" block variant="warning">Download</b-button>

                        <b-form-group :state="fileState">
                          <validation-provider name="File" #default="{ errors }" :rules="document == null ? 'required' : ''">
                            <b-form-file accept="application/pdf" @input="fileSelected" type="file" :state="fileState" placeholder="Choose a document or drop it here..." drop-placeholder="Drop PDF here..." class="animate__animated animate__fadeIn mt-1" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="mt-5">
                  <b-button type="submit" @click.prevent="saveResource" variant="warning" class="mr-1 float-right">
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios';
import { BTable } from 'bootstrap-vue';
import store from '@/store';
import settingsStoreModule from '../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import vSelect from 'vue-select';
import ProfileCropper from '@core/components/image-cropper/ProfileCropper.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import router from '@/router';

export default {
  components: {
    BTable,
    vSelect,

    ToastificationContent,
    BCardText,
    BTabs,
    BTab,
    ProfileCropper,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();
  },

  data() {
    return {
      id: router.currentRoute.params.id ?? null,
      loading: false,
      description: null,
      title: null,
      selectedFile: null,
      progress: 0,
      image: null,
      imageUrl: null,
      documentURL: store.state.app.documentURL,
      document: null,
    };
  },
  computed: {
    dataMeta() {
      return {};
    },

    titleState() {
      return this.title != null && this.title != '' ? true : false;
    },

    fileState() {
      return this.document != null && this.document != '' ? true : false;
    },

    imageState() {
      return this.imageUrl != null && this.imageUrl != '' ? true : false;
    },

    descriptionState() {
      return this.description != null && this.description != '' ? true : false;
    },
  },
  methods: {
    imageSelected(event) {
      const file = event ? event : null;
      if (file != null) {
        this.getBase64(file).then((data) => {
          this.image = data;
        });
      }
    },
    imageCropped(croppedImage) {
      if (croppedImage != null) {
        this.loading = true;
        let formData = new FormData();
        formData.append('file', croppedImage);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        axiosIns
          .post('uploadPreviewPhoto', formData, config)
          .then((response) => {
            this.imageUrl = this.documentURL + response.data;

            this.image = null;
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Cropped Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Not Successful',
                text: '⛔ Image Upload Not Successful',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.image = null;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadDocumentPDF', formData, config)
        .then((response) => {
          this.document = response.data;

          // this.tempImage = null;
          // this.image = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    getTool() {
      if (router.currentRoute.params.id) {
        store
          .dispatch('settings/getTool', { id: router.currentRoute.params.id })
          .then((response) => {
            this.description = response.data.description;
            this.title = response.data.title;
            this.imageUrl = response.data.image;
            this.document = response.data.document;
            this.loading = false;
          })
          .catch((error) => {});
      } else {
        this.loading = false;
      }
    },

    saveResource() {
      this.loading = true;
      this.$refs.resourceRules.validate().then((success) => {
        if (success) {
          if (this.id != null) {
            var data = {
              title: this.title,
              description: this.description,
              image: this.imageUrl,
              document: this.document ? this.document : null,
              type: 'tool',
              id: this.id,
            };

            axiosIns
              .post('updateResource', data)
              .then((response) => {
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Resource has been uploaded successfully',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                router.push({ name: 'support' });
              })
              .catch((error) => {
                this.loading = false;
                console.log(error);
              });
          } else {
            var data = {
              title: this.title,
              description: this.description,
              image: this.imageUrl,
              document: this.document ? this.documentURL + this.document : null,
              type: 'tool',
            };

            axiosIns
              .post('saveResource', data)
              .then((response) => {
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Resource has been uploaded successfully',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                router.push({ name: 'support' });
              })
              .catch((error) => {
                this.loading = false;
                console.log(error);
              });
          }
        } else {
          this.loading = false;
        }
      });
    },

    deleteResource() {
      this.$swal({
        title: 'Are you sure you want to delete the document?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,

        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          axiosIns
            .delete(`deleteResource/${this.id}`)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Resource has been deleted successfully',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              router.push({ name: 'support' });
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        }
      });
    },
  },
  created() {
    this.getTool();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}

.inactivePdf {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
</style>
