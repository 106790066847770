<template>
  <div>
    <tool-upload></tool-upload>
  </div>
</template>

<script>
import ToolUpload from './ToolUpload.vue';

export default {
  components: {
    ToolUpload,
  },

  data() {
    return {};
  },
};
</script>
